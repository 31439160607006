import React, { useState, useEffect } from "react";
import { Location } from "@reach/router";

import API from "../../components/api";
import Layout from "../../components/layout";

const Index = () => {
  return (
    <Layout>
      <API />
    </Layout>
  );
};

export default Index;
